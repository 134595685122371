import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { AUTH_ORIGIN } from '../environment';
import { ProcessingAnimation } from "@cargo/ui-kit/processing/processing";
import './login.scss';

/*
 * please check in with Aart before adding any import statements here.
 *
 * This component can render before most of the application is initialized. This means that
 * it's very important to not import modules that have direct/nested dependencies on parts of
 * the app that require the CRDT, admin Reducers & Selectors and routing (and others).
 */

let bounceAttempts = 0;
let singleUseAccessTokenToken = null;

if(typeof window !== 'undefined' && window.__ACCESS_TOKEN_NONCE__) {
	// set & delete from window.
	singleUseAccessTokenToken = window.__ACCESS_TOKEN_NONCE__;
	delete window.__ACCESS_TOKEN_NONCE__;
}

class LoginComponent extends Component {

	constructor(props) {

		super(props);

		const searchParams = new URLSearchParams(window.location.search);

		this.state = {
			error: null,
			loading: false,
			checkingCookieAuth: true,
			formView: props.canCreateNewAccount ? 'newAcct' : 'login',
			resetEmail: null,
			prevFormState: null,
			prefilledEmail: searchParams.get('email')
		}

		this.containerRef = React.createRef();

		const bounceJWTToken = searchParams.get('t');

		this.redirectURL = searchParams.get('rd');

		// remove token from search parameters
		if(searchParams.has('t')) {
			
			// delete
			searchParams.delete('t');

			let newSearchParams = searchParams.toString();

			if(newSearchParams.length > 0) {
				newSearchParams = '?' + newSearchParams;
			}

			// remove from URL without reloading page
			window.history.replaceState({}, document.title, window.location.origin + window.location.pathname + newSearchParams);

		}

		if(this.props.loginToUdotCargoOnly) {
			
			// render regular login screen
			this.state.checkingCookieAuth = false;

			return;
		
		}

		if(bounceAttempts >= 1) {
			// don't keep bouncing infinitely.
			// console.log('Max auto login attempts reached...')

			// render regular login screen
			this.state.checkingCookieAuth = false;

			// skip all the auto auth stuff below
			return;
		}

		if(bounceJWTToken === '') {

			// We returned from a bounce auth with an empty token. 
			// Don't do anything and use regular login window
			this.state.checkingCookieAuth = false;

		} else {

			let executeAuthBounce = false;

			if(this.props.domain_active && bounceJWTToken === null) {
				executeAuthBounce = true;
			}

			const requestData = {
				timeout: 3000,
				// auth using the JWT token returned from our bounce.
				jwt: bounceJWTToken,
				// we're on a custom domain and haven't bounced yet. Do an auth bounce
				bounce: executeAuthBounce,
				// the nonce used to grab auth by cookie
				nonce: singleUseAccessTokenToken
			};

			if(this.props.site_url) {
				requestData.site_url = this.props.site_url;
			}

			if(this.props.site_id) {
				requestData.site_id = this.props.site_id;
			}

			// first see if we can automatically login 
			// using cookie auth or a bounce
			this.props.getAuthToken(requestData).catch(e => {
				console.log('Failed to fetch auth token.')
			}).finally(() => {

				bounceAttempts++;

				if(executeAuthBounce) {
					// don't do anything. Show a blank screen while the redirect
					// is being executed
					return;
				}

				// wait one frame so the component hosting this login frame can re-render
				// and kill the login window before we render the window again. This prevents
				// login from flashing for a single frame
				requestAnimationFrame(() => {
					this.props.onCookieAuthComplete?.();
					this.setState({
						checkingCookieAuth: false
					});
				})
				
			});

		}

	}

	render() {

		// don't show anything while running the background cookie auth check
		if(this.state.checkingCookieAuth || this.props.cookieAuthOnly) {
			return null;
		}

		return <div 
			id="login" 
			className={`login-window${this.props.noOverlay === true ? ' not-overlaid' : ''}`}
			ref={this.containerRef}
			onMouseUp={(e)=>{
				if( this.props.canClickout && !e.target.closest('.login-modal') ){
					// Remove form via parent container.
					const removeLoginForm = new CustomEvent('remove-login-form');
					document.dispatchEvent( removeLoginForm );

					if( this.props.onClickout && !this.state.loading ){
						this.props.onClickout();
					}
				}
			}}
		>

			<div className="login-modal custom login-frame">

			<div className="login-header">
				<div className="logo-box">
					<div className="logo">
						<svg width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path fillRule="evenodd" clipRule="evenodd" d="M0.521458 11.5988H4.49769C4.90909 11.5988 5.2426 11.9403 5.2426 12.3615C5.2426 12.4348 5.23229 12.5076 5.212 12.5779L3.26311 19.3227C3.21643 19.4842 3.3065 19.6539 3.46431 19.7017C3.58148 19.7372 3.70795 19.6959 3.78333 19.5974L11.8889 9.01082C12.0666 8.77877 12.0269 8.44319 11.8002 8.26129C11.7084 8.18761 11.5952 8.14758 11.4785 8.14758H7.64746C7.23606 8.14758 6.90255 7.80611 6.90255 7.3849C6.90255 7.31158 6.91287 7.23865 6.9332 7.16835L8.89307 0.391703C8.93979 0.23015 8.84976 0.0604064 8.69197 0.0125694C8.57559 -0.0227134 8.44994 0.0178057 8.37431 0.115005L0.113608 10.7323C-0.065826 10.9629 -0.0286851 11.2988 0.196564 11.4825C0.288885 11.5578 0.403425 11.5988 0.521458 11.5988Z" fill="var(--ui-color-flat-reverse)"/>
						</svg>
					</div>
				</div>
				<div className="title">
					{ this.state.formView === 'newAcct' ? ('Login or Sign Up') : (
						this.state.formView === 'login' ? 'Login' : 'Reset Password'
					)}
				</div>
			</div>

				{ this.state.loading && ""}
				
				{/* Create Account */}

				{ this.state.formView === 'newAcct' ?
					<form>
						<div className="input-fields">
							{ 
								this.state.prefilledEmail ? 
										null 
									:
										<div className="text-input bars">
											<input
												autoFocus
												type="text"
												name="email"
												placeholder="Email Address..."
												onKeyDown={()=>{
													if( this.state.error !== null ){
														this.setState({ error: null })
													}
												}}
											/>
										</div>
							}
							<div className="text-input bars">
								<input
									type="password"
									name="password"
									placeholder="Password..."
									onKeyDown={()=>{
										if( this.state.error !== null ){
											this.setState({ error: null })
										}
									}}
								/>
							</div>
							{ this.state.error && 
								<div className="error" style={{color: 'red'}}>
									<span className="emoji">⚠️</span>&nbsp;{this.state.error}
								</div>
							}
						</div>

						<div className="login-options">
							<button 
								className="login"
								type="submit"
								style={{display: 'inline-block'}}
								onClick={e=>{
									e.preventDefault();

									this.setState({
										error: null,
										loading: true
									});

									const formData = new FormData(
										this.containerRef.current.querySelector('form')
									);

									let isValid = this.validateLogin(formData);

									if( isValid ){
										this.login(formData);
									}

								}}
							>
								{ this.state.loading && <ProcessingAnimation className="accent default-border-radius" />}
								OK
							</button>
							<button 
								className="forgot"
								style={{display: 'inline-block'}}
								onClick={e=>{
									e.preventDefault();

									this.setState({
										formView: 'forgot',
										error: null,
										prevFormState: 'newAcct'
									});

								}}
							>
								Forgot?
							</button>
							{/* <button  */}
							{/* 	className="forgot" */}
							{/* 	style={{display: 'inline-block'}} */}
							{/* 	onClick={e=>{ */}
							{/* 		e.preventDefault(); */}
							{/*  */}
							{/* 		this.setState({ */}
							{/* 			formView: 'login', */}
							{/* 			error: null */}
							{/* 		}); */}
							{/*  */}
							{/* 	}} */}
							{/* > */}
							{/* 	Or Login */}
							{/* </button> */}
						</div>
					</form>
					: ( null )}

				{/* Login */}
				{ this.state.formView === 'login' ? 
					<form>
						<div className="input-fields">
							{ 
								this.state.prefilledEmail ? 
										null 
									:
										<div className="text-input bars">
											<input
												autoFocus
												type="text"
												name="email"
												placeholder="Email Address..."
												onKeyDown={()=>{
													if( this.state.error !== null ){
														this.setState({ error: null })
													}
												}}
											/>
										</div>
							}
							<div className="text-input bars">
								<input
									type="password"
									name="password"
									placeholder="Password..."
									onKeyDown={()=>{
										if( this.state.error !== null ){
											this.setState({ error: null })
										}
									}}
								/>
							</div>
							{ this.state.error && 
								<div className="error" style={{color: 'red'}}>
									<span className="emoji">⚠️</span>&nbsp;{this.state.error}
								</div>
							}
						</div>

						<div className="login-options">
							<button 
								className="login"
								type="submit"
								style={{display: 'inline-block'}}
								onClick={e=>{
									e.preventDefault();

									this.setState({
										error: null,
										loading: true
									});

									const formData = new FormData(
										this.containerRef.current.querySelector('form')
									);
									
									this.login(formData);

								}}
							>
							{ this.state.loading && <ProcessingAnimation className="accent default-border-radius" />}
								OK
							</button>
							<button 
								className="forgot"
								style={{display: 'inline-block'}}
								onClick={e=>{
									e.preventDefault();

									this.setState({
										formView: 'forgot',
										error: null,
										prevFormState: 'login'
									});

								}}
							>
								Forgot?
							</button>
						</div>
					</form>
				: ( null )}

				{/* Forgot */}
				{ this.state.formView === 'forgot' ? 
					<>
						<form>
							<div className="enter-email">
								Enter an email address associated with your Cargo Account:
							</div>
							<div className="input-fields">
								<div className="text-input bars">
									<input
										autoFocus
										type="text"
										name="email"
										placeholder="Email Address..."
										onKeyDown={()=>{
											if( this.state.error !== null ){
												this.setState({ error: null })
											}
										}}
									/>
								</div>

								{ this.state.error && 
									<div className="error" style={{color: 'red'}}>
										<span className="emoji">⚠️</span>&nbsp;{this.state.error}
									</div>
								}
							</div>

							<div className="login-options">
								<button 
									className="login"
									type="submit"
									style={{display: 'inline-block'}}
									onClick={e=>{
										e.preventDefault();

										this.setState({
											error: null,
											loading: true
										});

										const formData = new FormData(
											this.containerRef.current.querySelector('form')
										);

										this.forgot(formData);
										
									}}
								>
								{ this.state.loading && <ProcessingAnimation className="accent default-border-radius" />}
									Submit
								</button>
								<button 
									className="forgot"
									style={{display: 'inline-block'}}
									onClick={e=>{
										e.preventDefault();

										this.setState({
											formView: this.state.prevFormState ? this.state.prevFormState : 'login',
											error: null
										});

									}}
								>
									Cancel
								</button>
							</div>
						</form>
					</>
				: ( null) }

				{/* Forgot */}
				{ this.state.formView === 'reset' ? 
					<>
						<form>
							<div className="enter-email">
								{ this.state.resetEmail ? (<>We’ve sent a reset code to <br/>{this.state.resetEmail}</>):(<>We’ve sent a reset code to the email associated with your Cargo Account:</>)} 							</div>
							<div className="input-fields">
								<div className="text-input bars">
									<input
										type="text"
										name="pwreset"
										placeholder="Enter Reset Code"
										onKeyDown={()=>{
											if( this.state.error !== null ){
												this.setState({ error: null })
											}
										}}
									/>
								</div>
								<div className="text-input bars">
									<input
										type="password"
										name="password"
										autocomplete="new-password"
										placeholder="Enter a new Password..."
										onKeyDown={()=>{
											if( this.state.error !== null ){
												this.setState({ error: null })
											}
										}}
									/>
								</div>
								<div className="text-input bars">
									<input
										type="password"
										name="password_reset_confirm"
										autocomplete="new-password"
										placeholder="Confirm new Password..."
										onKeyDown={()=>{
											if( this.state.error !== null ){
												this.setState({ error: null })
											}
										}}
									/>
								</div>

								{ this.state.error && 
									<div className="error" style={{color: 'red'}}>
										<span className="emoji">⚠️</span>&nbsp;{this.state.error}
									</div>
								}

							</div>

							<div className="login-options">
								<button 
									className="login"
									type="submit"
									style={{display: 'inline-block'}}
									onClick={e=>{

										e.preventDefault();

										const formData = new FormData(
											this.containerRef.current.querySelector('form')
										)

										let isValid = this.validateForm( formData );

										if( isValid ){

											this.setState({
												error: null,
												loading: true
											});

											// reset 
											this.forgot(formData);
										}

										if( !isValid ){
											this.setState({
												loading: false
											})
										}
										
									}}
								>
								{ this.state.loading && <ProcessingAnimation className="accent default-border-radius" />}
									OK
								</button>
								<button 
									className="forgot"
									style={{display: 'inline-block'}}
									onClick={e=>{
										e.preventDefault();

										this.setState({
											formView: 'login',
											error: null
										});

									}}
								>
									Cancel
								</button>
							</div>
						</form>
					</>
				: ( null) }

			</div>
		</div>
	}

	login = formData => {

		let checkLoginOrCreate = this.state.formView === 'newAcct' ? true : false ;
		let formURL = checkLoginOrCreate ? "/dispatch/admin/loginCreate" : "/dispatch/admin/login";
		formData.append('network', 'c3');

		if(this.props.site_url) {
			formData.append('site_url', this.props.site_url);
		}

		if (this.props.templateID) {
			formData.append('site_id', this.props.templateID);
		}

		if(this.state.prefilledEmail) {
			formData.append('email', this.state.prefilledEmail);
		}

		if( !this.props.site_url && !this.props.templateID && this.state.formView === 'newAcct' ){
			const state = window.store.getState();
			let siteId = AUTH_ORIGIN === 'https://cargo.site' ? 1597765 : 1791886;
			if( state ){
				let templates = state.templates ? state.templates.flatMap(template => template.sites) : [];
				if( templates.length > 0 ){
					let len = templates.length - 1;
					let random = Math.floor(Math.random() * len)
					let randomTemplate = templates[random];
					if( randomTemplate ){
						siteId = randomTemplate?.id ? randomTemplate.id : siteId;
					}
				}
			}
			
			formData.append('site_id', siteId );

		}

		fetch(AUTH_ORIGIN + formURL, {
			method: "post",
			body: formData,
			credentials: 'include'
		})
		.then(async response => {

			const data = await response.json();
			const { access_token, id, bounce_jwt } = data.jdata || {};

			if(access_token && id) {

				if(this.props.loginToUdotCargoOnly !== true) {
					// only set local site data if not just logging in to u.cargo
					this.props.setAuthData(access_token, id);
				}

				// on custom domains we need to also set cookies on cargo.site. Bounce there to set
				if(this.props.domain_active && bounce_jwt) {
					this.props.getAuthToken({
						// send back the JWT to auth on cargo.site
						reverse_bounce_jwt: bounce_jwt
					}).then(() => {

						if(this.redirectURL) {
							window.location.href = this.redirectURL;
						} else {
							this.props.onLoginSuccess?.(data.jdata)
						}

					});
				} else {

					if(this.redirectURL) {
						window.location.href = this.redirectURL;
					} else {
						this.props.onLoginSuccess?.(data.jdata);
					}
				}

			} else {

				// no access token. Something when wrong
				this.setState({
					error: data?.error || "Something went wrong. Please try again later.",
					loading: false
				});

			}

		})
		.catch(error => {
			console.log(error);

			this.setState({
				error: "Something went wrong. Please try again later.",
				loading: false
			});

		}).finally(() => {
			// Sometimes we want to keep loading until the form is removed manually...
			if( this.props.loadingTillFormRemoval === true ){ return }

			this.setState({
				loading: false
			});

		})

	}

	forgot = formData => {

		formData.append('network', 'c3');
		
		if(this.props.site_url) {
			formData.append('site_url', this.props.site_url);
		}

		fetch(AUTH_ORIGIN + "/dispatch/auth/handleResetFormPost", {
			method: "post",
			body: formData,
			credentials: 'include'
		})
		.then(async response => {

			const data = await response.json();

			if( data.error && data.error?.[0]?.length > 0 ){
				this.setState({
					error: data.error[0],
					loading: false
				});
			} else {
				// If we're on the forgot form, success means we should store the email
				// and set the view to the reset fields
				if( this.state.formView === 'forgot'){

					this.setState({
						formView: 'reset',
						resetEmail: formData.get('email')
					});

				} else {
					// We sucesfully reset the password. 
					// Now create a new form with the email
					// stored in state and password from last form
					// to submit fresh login request.
					const loginFormData = new FormData();

					loginFormData.set('email', this.state.resetEmail )
					loginFormData.set('password', formData.get('password') )

					this.login( loginFormData );

				}
			}

		})
		.catch(error => {
			console.log(error);

			this.setState({
				error: "Something went wrong. Please try again later.",
				loading: false
			});

		}).finally(() => {

			this.setState({
				loading: false
			});

		})

	}

	validateLogin (formData) {
		let errorMessage = null;

		for (const pair of formData.entries()) {
			const [key, value] = pair;
			if( key === 'email' && ( value === '' || !value ) && !this.state.prefilledEmail ){
				errorMessage = "Incorrect Username or Password."
			}
			if( key === 'password' && ( value === '' || !value ) && errorMessage === null ){
				errorMessage = "Password cannot be empty."
			}
		  }

		if( errorMessage ){
			this.setState({
				error: errorMessage,
				loading: false
			});

			return false;
		}

		return true;
	}

	validateForm (formData) {

		var resetCode = formData.get('pwreset').trim();
		var password = formData.get('password').trim();
		var password_confirm = formData.get('password_reset_confirm').trim();

		if (resetCode == "") {

			this.setState({
				error: "Reset code cannot be empty."
			});

			return false;
		}

		if (password == "" || password_confirm == "") {

			this.setState({
				error: "Password cannot be empty."
			});

			return false;
		}

		if(password != password_confirm) {

			this.setState({
				error: "Passwords do not match."
			});

			return false;
		}
		
		return true;

	}

	componentDidMount = () => {
		this.animateContainer();
	}

	componentDidUpdate = () => {
		this.animateContainer();
	}

	animateContainer = () => {
		if (this.props.animateIn === false) {
			setTimeout(() => {
				if( this.containerRef.current ){
					this.containerRef.current?.classList?.add('active-immediate');
				}
			})
		} else {
			if(this.containerRef.current && !this.containerRef.current.classList.contains('active')) {
				setTimeout(() => {
					if( this.containerRef?.current ){
						this.containerRef?.current?.classList.add('active');
					}
				})
			}
		}
		
	}

}

export const Login = connect(
	state => ({
		site_url: state.site?.site_url,
		site_id: state.site?.id,
		domain_active: state.site?.domain_active
	}), 
	(dispatch, ownProps) => bindActionCreators({
		getAuthToken: ownProps.getAuthTokenAction,
		setAuthData: (access_token, id) => {
			return {
				type: 'AUTHENTICATE_USER_FULFILLED', 
				payload: {
					data: {
						access_token,
						id
					}
				}
			}
		}
	}, dispatch)
)(LoginComponent);